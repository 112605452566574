import router from '@/router'
import axiosAPI from '@/axios-SPITZER'

async function login ({ commit, state }, { username, password, rememberUser }) {
  const response = await axiosAPI.post('/docplanner/schedule-sync/login', { username: username, password: password }, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
    }
  })

  const user = response.data.result
  user.apiInstance = user.customers[0].customerId
  if (user.token) {
    if (rememberUser) {
      localStorage.setItem('userToken', JSON.stringify(user))
      localStorage.setItem('rememberUser', JSON.stringify(user))
    } else {
      localStorage.setItem('userToken', JSON.stringify(user))
      localStorage.removeItem('rememberUser')
    }
  }
  commit('LOGIN_SUCCESS', user)

  router.push(state.destination || '/')
}

function logout ({ commit }) {
  localStorage.removeItem('userToken')
  commit('LOGOUT')
}

export default {
  login,
  logout,
}
