const user = state => state.user
const currentCustomer = state => state.currentCustomer
const isLoggedIn = state => !!state.user
const apiInstance = state => state.user.apiInstance

export default {
  user,
  currentCustomer,
  isLoggedIn,
  apiInstance,
}
