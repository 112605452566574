export default {
  SET_ALERT (state, value) {
    Object.assign(state.alert, value)
  },
  SET_ALERT_FALSE (state, value) {
    state.alert.snackbar = value
  },

  SET_HEALT_INSURANCE (state, payload) {
    state.healtInsurance = payload
  }
}
