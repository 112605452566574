import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';
import $util from './util'
import API from './axios-API'
import API_SPITZER from './axios-SPITZER'

Vue.config.productionTip = false

Vue.prototype.$api = API
Vue.prototype.$spitzer = API_SPITZER

Vue.prototype.$util = $util

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

console.log('NODE_ENV:', process.env.NODE_ENV)