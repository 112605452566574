import Vue from 'vue'
import Router from 'vue-router'
import jwt from 'jsonwebtoken'
import store from '../store/index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login'),
    },
    {
      path: '/',
      name: 'Dashboard',
      component: () => import('@/views/home'),
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.matched[0].path)
  const user = JSON.parse(localStorage.getItem('userToken'))
  let loggedIn = 0
  if (user) {
    const userToken = user.token
    await jwt.verify(userToken, process.env.VUE_APP_SECRET_JWT, (err, decoded) => {
      if (err) {
        console.log(err.message)
      }
      if (!decoded) {
        loggedIn = 0
      } else {
        loggedIn = 1
      }
    })
  }

  if (authRequired && !loggedIn) {
    console.log('Acess Forbidden!')
    store.commit('auth/CHANGE_DESTINATION', to.fullPath)
    return next('/login')
  }

  if (!store.getters['auth/user'].token && user) {
    store.commit('auth/LOGIN_SUCCESS', user)
  }
  next()
})

export default router

