
import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import alert from './modules/alert'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  actions: {
  },
  mutations: {
    SET_LOADING (state, value) {
      state.loading = value
    },
  },
  getters: {
    loading: state => state.loading,
  },
  state: {
    loading: false,
  },
  modules: {
    auth,
    alert,
  },
})

export default store
