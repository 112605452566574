import store from '../store'

export default {
    // ALERT
    $alert: function (text, color, timeout, mode, snackbar) {
      const config = {
        snackbar: snackbar,
        color: color,
        mode: mode,
        timeout: timeout,
        text: text
      }
      store.dispatch('alert/alert', config)
    },

    // LOADING
    $loading: function (value) {
      store.dispatch('setLoading', value)
    },
}