// ////////////////////////////////////////////////////////////////////////////////////////
// ALERTS

export default {
  alert ({ commit }, options) {
    const config = {
      snackbar: options.snackbar || true,
      color: options.color || 'error',
      mode: options.mode || '',
      timeout: options.timeout || 3000,
      text: options.text || 'Erro de comunicação com o servidor, por favor verifique sua conexão e tente novamente.'
    }
    commit('SET_ALERT', config)
  },
}
