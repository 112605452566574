import jwt from 'jsonwebtoken'
import axios from 'axios'
import store from './store'
import $util from './util'

const debug = process.env.DEBUG || true

const getToken = () => {
  const apiInstance = store.getters['auth/apiInstance']
  if (!apiInstance) { return store.dispatch('auth/logout') }
  const userToken = JSON.parse(localStorage.getItem('userToken')) || {}
  const decoded = jwt.verify(userToken.token, process.env.VUE_APP_SECRET_JWT)
  const payload = { ...decoded, customer: apiInstance }
  const resigned = jwt.sign(payload, process.env.VUE_APP_SECRET_JWT)
  return resigned
}

const getAddress = (route) => {
  const apiInstance = store.getters['auth/apiInstance']
  if (!apiInstance) { return store.dispatch('auth/logout') }
  const uri = process.env.VUE_APP_API_URL.split('//')
  if (process.env.NODE_ENV === 'development') {
    return `${uri[0]}//${uri[1]}${route}`
  }
  return `${uri[0]}//${apiInstance}.${uri[1]}${route}`
}

const api = axios.create({
  // domain: process.env.VUE_APP_API_URL,
  // baseURL: process.env.VUE_APP_API_URL,
})

export default {
  get: async function (address) {
    const token = await getToken()
    const fullAddress = getAddress(address)
    if (!fullAddress || !token) { return }

    if (debug) {
      console.log('-- axios -- GET:', fullAddress)
    }

    let response;
    try {
      response = await api.get(fullAddress, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'content-type': 'application/json',
          Authorization: token,
        }
      })
    } catch (error) {
      console.log('-- axios: Response Error --', error)
      $util.$alert()
    }
    if (response && response.messages && response.messages.length) {
      response.messages.map((message) => {
        $util.$alert(message, 'success')
      })
    }
    if (response && response.errors && response.errors.length) {
      response.errors.map((message) => {
        $util.$alert(message)
      })
    }
    $util.$alert('Dados carregados com sucesso!', 'success')
    return response
  },

  post: async function (address, data) {
    const token = await getToken()
    const fullAddress = getAddress(address)
    if (!fullAddress || !token) { return }
    console.log('-- axios -- POST:', fullAddress)

    let response;
    try {
      response = await api.post(fullAddress, data, {
        headers: {
          'content-type': 'application/json',
          Authorization: token,
        }
      })
      if (response && response.messages && response.messages.length) {
        response.messages.map((message) => {
          $util.$alert(message, 'success')
        })
      }
      if (response && response.errors && response.errors.length) {
        response.errors.map((message) => {
          $util.$alert(message)
        })
      }
    } catch (error) {
      console.log('-- axios: Response Error --', error)
      $util.$alert()
    }
    $util.$alert('Dados carregados com sucesso!', 'success')
    return response
  },

  delete: async function (address) {
    const token = await getToken()
    const fullAddress = getAddress(address)
    if (!fullAddress || !token) { return }

    if (debug) {
      console.log('-- axios -- GET:', fullAddress)
    }

    let response;
    try {
      response = await api.delete(fullAddress, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'content-type': 'application/json',
          Authorization: token,
        }
      })
    } catch (error) {
      console.log('-- axios: Response Error --', error)
      $util.$alert()
    }
    if (response && response.messages && response.messages.length) {
      response.messages.map((message) => {
        $util.$alert(message, 'success')
      })
    }
    if (response && response.errors && response.errors.length) {
      response.errors.map((message) => {
        $util.$alert(message)
      })
    }
    $util.$alert('Dados carregados com sucesso!', 'success')
    return response
  },

}