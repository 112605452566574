const LOGIN_SUCCESS = (state, user) => {
  state.user = user
}

const LOGIN_FAILURE = (state) => {
  state.user = null
}

const LOGOUT = (state) => {
  state.user = null
}

const CHANGE_DESTINATION = (state, value) => {
  state.destination = value
}

const SET_CURRENT_CUSTOMER = (state, value) => {
  state.currentCustomer = value
}

export default {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  CHANGE_DESTINATION,
  SET_CURRENT_CUSTOMER,
}
